// Assets
import * as actionTypes from '../actionTypes'

// Types
import FluxTypes from '../../../types/FluxTypes'

// Catalog register value
export const setCatalogProductName: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_NAME,
  value
})

export const setCatalogProductQuantity: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_QUANTITY,
  value
})

export const setCatalogProductMeasurementUnit: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_MEASUREMENT_UNIT,
  value
})

export const setCatalogProductSku: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_SKU,
  value
})

export const setCatalogProductQuota: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_QUOTA,
  value
})

export const setCatalogProductPartnerType: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_PARTNER_TYPE,
  value
})

export const setCatalogProductBrand: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_BRAND,
  value
})

export const setCatalogProductCategory: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_CATEGORY,
  value
})

export const setCatalogProductProvider: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_PROVIDER,
  value
})

export const setCatalogProductUnitDrive: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_UNIT_DRIVE,
  value
})

export const setCatalogProductType: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_TYPE,
  value
})

export const setCatalogProductBasePrice: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_BASE_PRICE,
  value
})

export const setCatalogProductBaseCost: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_BASE_COST,
  value
})

export const setCatalogProductSellinSku: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_SELL_IN_SKU,
  value
})

export const setCatalogProductBarCode: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_BAR_CODE,
  value
})

export const setCatalogProductAvailabilityLocation: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_AVAILABILITY_LOCATION,
  value
})

export const setCatalogProductSellinSkus: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_SELL_IN_SKUS,
  value
})

export const setCatalogProductBarcodes: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_BAR_CODES,
  value
})

export const setCatalogProductAvailabilities: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_AVAILABILITIES,
  value
})

export const setCatalogProductTypeofPrices: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_TYPEOF_PRICES,
  value
})

// Format register form

export const setFormatCatalogRegisterForm: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_FORMAT_CATALOG_REGISTER_FORM
})

// Errors
export const setCatalogProductNameErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_NAME_ERRORS,
  errors
})

export const setCatalogProductQuantityErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_QUANTITY_ERRORS,
  errors
})

export const setCatalogProductMeasurementUnitErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_MEASUREMENT_UNIT_ERRORS,
  errors
})

export const setCatalogProductSkuErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_SKU_ERRORS,
  errors
})

export const setCatalogProductQuotaErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_QUOTA_ERRORS,
  errors
})

export const setCatalogProductPartnerTypeErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_PARTNER_TYPE_ERRORS,
  errors
})

export const setCatalogProductBrandErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_BRAND_ERRORS,
  errors
})

export const setCatalogProductCategoryErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_CATEGORY_ERRORS,
  errors
})

export const setCatalogProductProviderErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_PROVIDER_ERRORS,
  errors
})

export const setCatalogProductUnitDriveErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_UNIT_DRIVE_ERRORS,
  errors
})

export const setCatalogProductTypeErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_TYPE_ERRORS,
  errors
})

export const setCatalogProductBasePriceErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_BASE_PRICE_ERRORS,
  errors
})

export const setCatalogProductBaseCostErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_BASE_COST_ERRORS,
  errors
})

export const setCatalogProductBarCodeErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_BAR_CODE_ERRORS,
  errors
})

export const setCatalogProductSellinSkuErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_SELL_IN_SKU_ERRORS,
  errors
})

export const setCatalogProductAvailabilityLocationErrors: FluxTypes.ActionCreator =
  (errors: string[]) => ({
    type: actionTypes.SET_CATALOG_PRODUCT_AVAILABILITY_LOCATION_ERRORS,
    errors
  })

// Components
export const setCatalogModalEditPriceVisible: FluxTypes.ActionCreator = (
  value: boolean
) => ({
  type: actionTypes.SET_CATALOG_MODAL_EDIT_PRICE_VISIBLE,
  value
})

export const setCatalogModalProductRegisterVisible: FluxTypes.ActionCreator = (
  value: boolean
) => ({
  type: actionTypes.SET_CATALOG_MODAL_PRODUCT_REGISTER_VISIBLE,
  value
})

// Prices by location
export const setFormatCatalogRegisterPricesLocations: FluxTypes.ActionCreator =
  () => ({
    type: actionTypes.SET_FORMAT_CATALOG_REGISTER_PRICES_LOCATION_FORM
  })

export const setCatalogProductPrice: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_PRICE,
  value
})

export const setCatalogProductPriceLocation: FluxTypes.ActionCreator = (
  value: string
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_PRICE_LOCATION,
  value
})

export const setCatalogProductPricesLocations: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_PRICES_LOCATIONS,
  value
})

export const setCatalogProductLocations: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_LOCATION,
  value
})

export const setCatalogEditSearchPriceLocation: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_SEARCH_PRICE_LOCATION,
  value
})

export const setCatalogOnConfirmModal: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_ON_CONFIRM_MODAL,
  value
})

export const setCatalogProductPriceErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_PRICE_ERRORS,
  errors
})

export const setCatalogProductPriceLocationErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_PRICE_LOCATION_ERRORS,
  errors
})

export const setCatalogEditModalVisible: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_MODAL_VISIBLE,
  value
})

export const setCatalogEditIndex: FluxTypes.ActionCreator = (value: any) => ({
  type: actionTypes.SET_CATALOG_EDIT_INDEX,
  value
})

export const setCatalogOnEditingValues: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_VALUES,
  value
})

//* //////////////////////////////////////////////////////////////
//* CATALOG EDIT PRODUCTS ///////////////////////////////////////
//* ////////////////////////////////////////////////////////////

export const setCatalogEditProductName: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_NAME,
  value
})

export const setCatalogEditProductQuantity: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_QUANTITY,
  value
})
export const setCatalogEditProductMeasurementUnit: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_MEASUREMENT_UNIT,
  value
})
export const setCatalogEditProductSku: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_SKU,
  value
})
export const setCatalogEditProductQuota: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_QUOTA,
  value
})
export const setCatalogEditProductPartnerType: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_PARTNER_TYPE,
  value
})
export const setCatalogEditProductBrand: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_BRAND,
  value
})
export const setCatalogEditProductCategory: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_CATEGORY,
  value
})
export const setCatalogEditProductProvider: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_PROVIDER,
  value
})
export const setCatalogEditProductUnitDrive: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_UNIT_DRIVE,
  value
})
export const setCatalogEditProductType: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_TYPE,
  value
})

export const setCatalogEditProductTypeofPrice: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_TYPE_OF_PRICE,
  value
})

export const setCatalogEditProductBasePrice: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_BASE_PRICE,
  value
})

export const setCatalogEditProductBaseCost: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_BASE_COST,
  value
})

export const setCatalogEditProductSellinSku: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_SELL_IN_SKU,
  value
})

export const setCatalogEditProductSellinSkus: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_SELL_IN_SKUS,
  value
})

export const setCatalogEditProductSellinSkuErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_SELL_IN_SKUS_ERRORS,
  errors
})

export const setCatalogEditProductBarCode: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_BAR_CODE,
  value
})

export const setCatalogEditProductBarcodes: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_BAR_CODES,
  value
})

export const setCatalogEditProductBarCodeErrors: FluxTypes.ActionCreator = (
  errors: string[]
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_BAR_CODES_ERRORS,
  errors
})

export const setCatalogEditProductAvailabilityLocationErrors: FluxTypes.ActionCreator =
  (errors: string[]) => ({
    type: actionTypes.SET_CATALOG_EDIT_PRODUCT_AVAILABILITY_LOCATION_ERRORS,
    errors
  })

export const setCatalogEditProductAvailabilityLocation: FluxTypes.ActionCreator =
  (value: any) => ({
    type: actionTypes.SET_CATALOG_EDIT_PRODUCT_AVAILABILITY_LOCATIONS,
    value
  })

export const setCatalogEditProductAvailabilities: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_AVAILABILITIES,
  value
})

export const setCatalogEditProductPrice: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_PRICE,
  value
})

//productPriceLocation
export const setCatalogEditProductSearchPriceLocation: FluxTypes.ActionCreator =
  (value: any) => ({
    type: actionTypes.SET_CATALOG_EDIT_PRODUCT_SEARCH_PRICE_LOCATION,
    value
  })

// export const setCatalogEditEditSearchPriceLocation: FluxTypes.ActionCreator = (
//   value: any
// ) => ({
//   type: actionTypes.SET_CATALOG_EDIT_PRODUCT_SEARCH_PRICE_LOCATION,
//   value
// })

export const setCatalogEditProductPricesLocations: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_PRICES_LOCATIONS,
  value
})

export const setCatalogEditProductLocations: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_LOCATIONS,
  value
})

export const setCatalogEditModalSearchPriceLocation: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_MODAL_PRODUCT_SEARCH_PRICE_LOCATION,
  value
})

export const setCatalogEditModalProductPriceLocation: FluxTypes.ActionCreator =
  (value: any) => ({
    type: actionTypes.SET_CATALOG_EDIT_MODAL_PRODUCT_PRICE_LOCATION,
    value
  })

export const setCatalogEditOnConfirmModal: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_ON_CONFIRM_MODAL,
  value
})

export const setFormatCatalogEditForm: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_FORMAT_CATALOG_EDIT_PRODUCT
})

//! //////////////////////////////////////////////////////////////
//! CATALOG EDIT ERRORS /////////////////////////////////////////
//! ////////////////////////////////////////////////////////////

export const setCatalogEditProductNameErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_NAME_ERRORS,
  errors
})

export const setCatalogEditProductQuantityErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_QUANTITY_ERRORS,
  errors
})
export const setCatalogEditProductMeasurementUnitErrors: FluxTypes.ActionCreator =
  (errors: any) => ({
    type: actionTypes.SET_CATALOG_EDIT_PRODUCT_MEASUREMENT_UNIT_ERRORS,
    errors
  })
export const setCatalogEditProductSkuErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_SKU_ERRORS,
  errors
})
export const setCatalogEditProductQuotaErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_QUOTA_ERRORS,
  errors
})
export const setCatalogEditProductPartnerTypeErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_PARTNER_TYPE_ERRORS,
  errors
})
export const setCatalogEditProductBrandErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_BRAND_ERRORS,
  errors
})
export const setCatalogEditProductCategoryErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_CATEGORY_ERRORS,
  errors
})
export const setCatalogEditProductProviderErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_PROVIDER_ERRORS,
  errors
})
export const setCatalogEditProductUnitDriveErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_UNIT_DRIVE_ERRORS,
  errors
})
export const setCatalogEditProductTypeErrors: FluxTypes.ActionCreator = (
  errors: any
) => ({
  type: actionTypes.SET_CATALOG_EDIT_PRODUCT_AVAILABILITY_LOCATION_ERRORS,
  errors
})

//? //////////////////////////////////////////////////////////////
//? CATALOG SEARCH //////////////////////////////////////////////
//? ////////////////////////////////////////////////////////////

export const setCatalogSearchByName: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_SEARCH_BY_NAME,
  value
})

export const setCatalogSearchByCode: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_SEARCH_BY_CODE,
  value
})

export const setCatalogSearchBySku: FluxTypes.ActionCreator = (value: any) => ({
  type: actionTypes.SET_CATALOG_PRODUCT_SEARCH_BY_SKU,
  value
})

export const setCatalogSearchFormat: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_FORMAT_CATALOG_PRODUCT_SEARCH
})

//Disclaimer Modal
export const setCatalogDisclaimerModal: FluxTypes.ActionCreator = (
  value: any
) => ({
  type: actionTypes.SET_CATALOG_DISCLAIMER_MODAL,
  value
})

export const setFormatCatalogDisclaimerModal: FluxTypes.ActionCreator = () => ({
  type: actionTypes.SET_FORMAT_CATALOG_DISCLAIMER_MODAL
})
