// Assets
import { standardStringFilter, onlyDigitsFilter } from '../../utils/forms'
import * as actionTypes from '../../actions/catalog/actionTypes'

// Types
import CatalogStoreTypes from '../../types/CatalogStoreTypes'
import FluxTypes from '../../types/FluxTypes'

const reducer: (
  state: CatalogStoreTypes.CatalogState,
  action: FluxTypes.ActionObject
) => CatalogStoreTypes.CatalogState = (state, action) => {
  let newState = state

  switch (action.type) {
    case actionTypes.SET_FORMAT_CATALOG_REGISTER_FORM:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productName: {
              ...state.register.productName,
              value: '',
              errors: []
            },
            productQuantity: {
              ...state.register.productQuantity,
              value: '',
              errors: []
            },
            productMeasurementUnit: {
              ...state.register.productMeasurementUnit,
              value: '',
              errors: []
            },
            productSku: {
              ...state.register.productSku,
              value: '',
              errors: []
            },
            productQuota: {
              ...state.register.productQuota,
              value: '',
              errors: []
            },
            productPartnerType: {
              ...state.register.productPartnerType,
              value: '',
              errors: []
            },
            productBrand: {
              ...state.register.productBrand,
              value: '',
              errors: []
            },
            productCategory: {
              ...state.register.productCategory,
              value: '',
              errors: []
            },
            productProvider: {
              ...state.register.productProvider,
              value: '',
              errors: []
            },
            productUnitDrive: {
              ...state.register.productUnitDrive,
              value: '',
              errors: []
            },
            productType: {
              ...state.register.productType,
              value: '',
              errors: []
            },
            productBasePrice: {
              ...state.register.productBasePrice,
              value: '',
              errors: []
            },
            productBaseCost: {
              ...state.register.productBaseCost,
              value: '',
              errors: []
            },
            productSellinSku: {
              ...state.register.productSellinSku,
              value: '',
              errors: []
            },
            productBarCode: {
              ...state.register.productBarCode,
              value: '',
              errors: []
            },
            productAvailabilityLocation: {
              ...state.register.productAvailabilityLocation,
              value: '',
              errors: []
            },
            productTypeofPrices: {
              ...state.register.productTypeofPrices,
              selectedValue: 'base-price'
            },
            productSellinSkus: [],
            productBarcodes: [],
            productAvailabilities: []
          }
        }
      }
      break

    // ---- Value
    case actionTypes.SET_CATALOG_PRODUCT_NAME:
      {
        const filteredValue = standardStringFilter(action.value, {
          maxLength: 150
        })
        newState = {
          ...state,
          register: {
            ...state.register,
            productName: {
              ...state.register.productName,
              value: filteredValue
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_QUANTITY:
      {
        const filteredValue = onlyDigitsFilter(action.value)
        newState = {
          ...state,
          register: {
            ...state.register,
            productQuantity: {
              ...state.register.productQuantity,
              value: filteredValue
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_MEASUREMENT_UNIT:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productMeasurementUnit: {
              ...state.register.productMeasurementUnit,
              value: action.value
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_SKU:
      {
        const filteredValue = onlyDigitsFilter(action.value, {
          isStringOfNumbers: true
        })
        newState = {
          ...state,
          register: {
            ...state.register,
            productSku: {
              ...state.register.productSku,
              value: filteredValue
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_QUOTA:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productQuota: {
              ...state.register.productQuota,
              value: action.value
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_PARTNER_TYPE:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productPartnerType: {
              ...state.register.productPartnerType,
              value: action.value
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_BRAND:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productBrand: {
              ...state.register.productBrand,
              value: action.value
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_CATEGORY:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productCategory: {
              ...state.register.productCategory,
              value: action.value
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_PROVIDER:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productProvider: {
              ...state.register.productProvider,
              value: action.value
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_UNIT_DRIVE:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productUnitDrive: {
              ...state.register.productUnitDrive,
              value: action.value
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_TYPE:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productType: {
              ...state.register.productType,
              value: action.value
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_BASE_PRICE:
      {
        const filteredValue = action.value
        newState = {
          ...state,
          register: {
            ...state.register,
            productBasePrice: {
              ...state.register.productBasePrice,
              value: filteredValue
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_BASE_COST:
      {
        const filteredValue = action.value
        newState = {
          ...state,
          register: {
            ...state.register,
            productBaseCost: {
              ...state.register.productBaseCost,
              value: filteredValue
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_SELL_IN_SKU:
      {
        const filteredValue = onlyDigitsFilter(action.value, {
          isStringOfNumbers: true
        })
        newState = {
          ...state,
          register: {
            ...state.register,
            productSellinSku: {
              ...state.register.productSellinSku,
              value: filteredValue
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_BAR_CODE:
      {
        const filteredValue = onlyDigitsFilter(action.value, {
          isStringOfNumbers: true
        })
        newState = {
          ...state,
          register: {
            ...state.register,
            productBarCode: {
              ...state.register.productBarCode,
              value: filteredValue
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_AVAILABILITY_LOCATION:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productAvailabilityLocation: {
              ...state.register.productAvailabilityLocation,
              value: action.value
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_SELL_IN_SKUS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productSellinSkus: action.value
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_BAR_CODES:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productBarcodes: action.value
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_AVAILABILITIES:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productAvailabilities: action.value
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_TYPEOF_PRICES:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productTypeofPrices: {
              ...state.register.productTypeofPrices,
              selectedValue: action.value
            }
          }
        }
      }
      break

    // ---- Errors
    case actionTypes.SET_CATALOG_PRODUCT_NAME_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productName: {
              ...state.register.productName,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_QUANTITY_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productQuantity: {
              ...state.register.productQuantity,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_MEASUREMENT_UNIT_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productMeasurementUnit: {
              ...state.register.productMeasurementUnit,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_SKU_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productSku: {
              ...state.register.productSku,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_QUOTA_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productQuota: {
              ...state.register.productQuota,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_PARTNER_TYPE_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productPartnerType: {
              ...state.register.productPartnerType,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_BRAND_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productBrand: {
              ...state.register.productBrand,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_CATEGORY_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productCategory: {
              ...state.register.productCategory,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_PROVIDER_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productProvider: {
              ...state.register.productProvider,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_UNIT_DRIVE_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productUnitDrive: {
              ...state.register.productUnitDrive,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_TYPE_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productType: {
              ...state.register.productType,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_BASE_PRICE_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productBasePrice: {
              ...state.register.productBasePrice,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_BASE_COST_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productBaseCost: {
              ...state.register.productBaseCost,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_BAR_CODE_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productBarCode: {
              ...state.register.productBarCode,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_SELL_IN_SKU_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productSellinSku: {
              ...state.register.productSellinSku,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_PRODUCT_AVAILABILITY_LOCATION_ERRORS:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            productAvailabilityLocation: {
              ...state.register.productAvailabilityLocation,
              errors: action.errors
            }
          }
        }
      }
      break

    // Components
    case actionTypes.SET_CATALOG_MODAL_EDIT_PRICE_VISIBLE:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            isCatalogModalEditPriceVisible: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_MODAL_PRODUCT_REGISTER_VISIBLE:
      {
        newState = {
          ...state,
          register: {
            ...state.register,
            isProductRegisterModalVisible: action.value
          }
        }
      }
      break

    // Catalog prices by location
    case actionTypes.SET_FORMAT_CATALOG_REGISTER_PRICES_LOCATION_FORM:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            productPrice: {
              ...state.registerPriceByLocation.productPrice,
              value: '',
              errors: []
            },
            productPriceLocation: {
              ...state.registerPriceByLocation.productPriceLocation,
              value: '',
              errors: []
            },
            editSearchPriceLocation: {
              ...state.registerPriceByLocation.editSearchPriceLocation,
              value: '',
              errors: []
            },
            productPricesLocations: [],
            productLocations: [],
            editProductPriceLocation: {
              ...state.registerPriceByLocation.editProductPriceLocation,
              modalVisible: false,
              editIndex: null,
              value: null
            },
            onConfirmModal: false
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_PRODUCT_PRICE:
      {
        const filteredValue = action.value
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            productPrice: {
              ...state.registerPriceByLocation.productPrice,
              value: filteredValue
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_PRODUCT_LOCATION:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            productLocations: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_PRODUCT_PRICE_LOCATION:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            productPriceLocation: {
              ...state.registerPriceByLocation.productPriceLocation,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_ON_CONFIRM_MODAL:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            onConfirmModal: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_SEARCH_PRICE_LOCATION:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            editSearchPriceLocation: {
              ...state.registerPriceByLocation.editSearchPriceLocation,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_PRODUCT_PRICES_LOCATIONS:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            productPricesLocations: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_PRODUCT_PRICE_ERRORS:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            productPrice: {
              ...state.registerPriceByLocation.productPrice,
              errors: action.errors
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_PRODUCT_PRICE_LOCATION_ERRORS:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            productPriceLocation: {
              ...state.registerPriceByLocation.productPriceLocation,
              errors: action.errors
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_MODAL_VISIBLE:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            editModalVisible: action.value
          }
        }
      }
      break

    //* Edit products

    case actionTypes.SET_FORMAT_CATALOG_EDIT_PRODUCT:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductName: {
              ...state.editProduct.editProductName,
              value: '',
              errors: []
            },
            editProductQuantity: {
              ...state.editProduct.editProductQuantity,
              value: '',
              errors: []
            },
            editProductMeasurementUnit: {
              ...state.editProduct.editProductMeasurementUnit,
              value: '',
              errors: []
            },
            editProductSku: {
              ...state.editProduct.editProductSku,
              value: '',
              errors: []
            },
            editProductQuota: {
              ...state.editProduct.editProductQuota,
              value: '',
              errors: []
            },
            editProductPartnerType: {
              ...state.editProduct.editProductPartnerType,
              value: '',
              errors: []
            },
            editProductBrand: {
              ...state.editProduct.editProductBrand,
              value: '',
              errors: []
            },
            editProductCategory: {
              ...state.editProduct.editProductCategory,
              value: '',
              errors: []
            },
            editProductProvider: {
              ...state.editProduct.editProductProvider,
              value: '',
              errors: []
            },
            editProductUnitDrive: {
              ...state.editProduct.editProductUnitDrive,
              value: '',
              errors: []
            },
            editProductType: {
              ...state.editProduct.editProductType,
              value: '',
              errors: []
            },
            editProductTypeofPrice: {
              ...state.editProduct.editProductTypeofPrice,
              selectedValue: 'base-price'
            },
            editProductBasePrice: {
              ...state.editProduct.editProductBasePrice,
              value: '',
              errors: []
            },
            editProductBaseCost: {
              ...state.editProduct.editProductBaseCost,
              value: '',
              errors: []
            },
            editProductSellinSku: {
              ...state.editProduct.editProductSellinSku,
              value: '',
              errors: []
            },
            editProductSellinSkus: [],
            editProductBarCode: {
              ...state.editProduct.editProductBarCode,
              value: '',
              errors: []
            },
            editProductBarcodes: [],
            editProductAvailabilityLocation: {
              ...state.editProduct.editProductAvailabilityLocation,
              value: '',
              errors: []
            },
            editProductAvailabilities: [],
            editProductPrice: {
              ...state.editProduct.editProductPrice,
              value: '',
              errors: []
            },
            editProductSearchPriceLocation: {
              ...state.editProduct.editProductSearchPriceLocation,
              value: '',
              errors: []
            },
            editModalSearchPriceLocation: {
              ...state.editProduct.editModalSearchPriceLocation,
              value: '',
              errors: []
            },
            editProductPricesLocations: [],
            editProductLocations: [],
            editModalProductPriceLocation: {
              modalVisible: false,
              editIndex: null,
              value: null
            },
            onConfirmModal: false
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_NAME:
      {
        const filteredValue = standardStringFilter(String(action.value), {
          maxLength: 150
        })
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductName: {
              ...state.editProduct.editProductName,
              value: filteredValue
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_QUANTITY:
      {
        const filteredValue = onlyDigitsFilter(String(action.value))
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductQuantity: {
              ...state.editProduct.editProductQuantity,
              value: filteredValue
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_MEASUREMENT_UNIT:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductMeasurementUnit: {
              ...state.editProduct.editProductMeasurementUnit,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_SKU:
      {
        const filteredValue = onlyDigitsFilter(String(action.value), {
          isStringOfNumbers: true
        })
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductSku: {
              ...state.editProduct.editProductSku,
              value: filteredValue
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_QUOTA:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductQuota: {
              ...state.editProduct.editProductQuota,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_PARTNER_TYPE:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductPartnerType: {
              ...state.editProduct.editProductPartnerType,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_BRAND:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductBrand: {
              ...state.editProduct.editProductBrand,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_CATEGORY:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductCategory: {
              ...state.editProduct.editProductCategory,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_PROVIDER:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductProvider: {
              ...state.editProduct.editProductProvider,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_UNIT_DRIVE:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductUnitDrive: {
              ...state.editProduct.editProductUnitDrive,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_TYPE:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductType: {
              ...state.editProduct.editProductType,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_TYPE_OF_PRICE:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductTypeofPrice: {
              ...state.editProduct.editProductTypeofPrice,
              selectedValue: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_BASE_PRICE:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductBasePrice: {
              ...state.editProduct.editProductBasePrice,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_BASE_COST:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductBaseCost: {
              ...state.editProduct.editProductBaseCost,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_SELL_IN_SKU:
      {
        const filteredValue = onlyDigitsFilter(action.value, {
          isStringOfNumbers: true
        })
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductSellinSku: {
              ...state.editProduct.editProductSellinSku,
              value: filteredValue
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_SELL_IN_SKUS_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductSellinSku: {
              ...state.editProduct.editProductSellinSku,
              errors: action.errors
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_BAR_CODE:
      {
        const filteredValue = onlyDigitsFilter(action.value, {
          isStringOfNumbers: true
        })
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductBarCode: {
              ...state.editProduct.editProductBarCode,
              value: filteredValue
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_BAR_CODES_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductBarCode: {
              ...state.editProduct.editProductBarCode,
              errors: action.errors
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_AVAILABILITY_LOCATION_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductAvailabilityLocation: {
              ...state.editProduct.editProductAvailabilityLocation,
              errors: action.errors
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_SELL_IN_SKUS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductSellinSkus: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_BAR_CODES:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductBarcodes: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_AVAILABILITY_LOCATIONS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductAvailabilityLocation: {
              ...state.editProduct.editProductAvailabilityLocation,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_AVAILABILITIES:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductAvailabilities: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_PRICE:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductPrice: {
              ...state.editProduct.editProductPrice,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_SEARCH_PRICE_LOCATION:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductSearchPriceLocation: {
              ...state.editProduct.editProductSearchPriceLocation,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_MODAL_PRODUCT_SEARCH_PRICE_LOCATION:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editModalSearchPriceLocation: {
              ...state.editProduct.editModalSearchPriceLocation,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_LOCATIONS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductLocations: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_PRICES_LOCATIONS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductPricesLocations: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_MODAL_PRODUCT_LOCATIONS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductLocations: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_INDEX:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            editIndex: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_MODAL_PRODUCT_PRICE_LOCATION:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editModalProductPriceLocation: action.value
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_VALUES:
      {
        newState = {
          ...state,
          registerPriceByLocation: {
            ...state.registerPriceByLocation,
            onEditingValues: action.value
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_ON_CONFIRM_MODAL:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editOnConfirmModal: action.value
          }
        }
      }
      break
    //! //////////////////////////////////////////////////////////////
    //! CATALOG EDIT ERRORS /////////////////////////////////////////
    //! ////////////////////////////////////////////////////////////

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_NAME_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductName: {
              ...state.editProduct.editProductName,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_QUANTITY_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductQuantity: {
              ...state.editProduct.editProductQuantity,
              errors: action.errors
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_EDIT_PRODUCT_MEASUREMENT_UNIT_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductMeasurementUnit: {
              ...state.editProduct.editProductMeasurementUnit,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_SKU_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductSku: {
              ...state.editProduct.editProductSku,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_QUOTA_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductQuota: {
              ...state.editProduct.editProductQuota,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_PARTNER_TYPE_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductPartnerType: {
              ...state.editProduct.editProductPartnerType,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_BRAND_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductBrand: {
              ...state.editProduct.editProductBrand,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_CATEGORY_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductCategory: {
              ...state.editProduct.editProductCategory,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_PROVIDER_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductProvider: {
              ...state.editProduct.editProductProvider,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_UNIT_DRIVE_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductUnitDrive: {
              ...state.editProduct.editProductUnitDrive,
              errors: action.errors
            }
          }
        }
      }
      break
    case actionTypes.SET_CATALOG_EDIT_PRODUCT_TYPE_ERRORS:
      {
        newState = {
          ...state,
          editProduct: {
            ...state.editProduct,
            editProductType: {
              ...state.editProduct.editProductType,
              errors: action.errors
            }
          }
        }
      }
      break

    //? //////////////////////////////////////////////////////////////
    //? CATALOG SEARCH //////////////////////////////////////////////
    //? ////////////////////////////////////////////////////////////

    case actionTypes.SET_CATALOG_PRODUCT_SEARCH_BY_NAME:
      {
        newState = {
          ...state,
          productSearch: {
            ...state.productSearch,
            searchByName: {
              ...state.productSearch.searchByName,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_PRODUCT_SEARCH_BY_CODE:
      {
        newState = {
          ...state,
          productSearch: {
            ...state.productSearch,
            searchByCode: {
              ...state.productSearch.searchByCode,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_PRODUCT_SEARCH_BY_SKU:
      {
        newState = {
          ...state,
          productSearch: {
            ...state.productSearch,
            searchBySku: {
              ...state.productSearch.searchBySku,
              value: action.value
            }
          }
        }
      }
      break

    case actionTypes.SET_FORMAT_CATALOG_PRODUCT_SEARCH:
      {
        newState = {
          ...state,
          productSearch: {
            ...state.productSearch,
            searchByName: {
              ...state.productSearch.searchByName,
              value: 'reset'
            },
            searchByCode: {
              ...state.productSearch.searchByCode,
              value: ''
            },
            searchBySku: {
              ...state.productSearch.searchBySku,
              value: ''
            }
          }
        }
      }
      break

    case actionTypes.SET_CATALOG_DISCLAIMER_MODAL:
      {
        newState = {
          ...state,
          disclaimerModal: action.value
        }
      }
      break

    case actionTypes.SET_FORMAT_CATALOG_DISCLAIMER_MODAL:
      {
        newState = {
          ...state,
          disclaimerModal: {
            isVisible: false,
            title: '',
            message: '',
            errors: []
          }
        }
      }
      break

    default:
      return newState
  }

  return newState
}

export default reducer
